import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthCallbackComponent } from "./auth-callback/auth-callback.component";
import { DefaultLayoutComponent } from "./containers/default-layout/default-layout.component";
import { HomeComponent } from "./pages/home/home.component";
import { AuthGuardService } from "./services/auth-guard.service";
// Import Containers
export const routes: Routes = [
  {
    path: "",
    redirectTo: "home",
    pathMatch: "full"
  },
  { path: 'auth-callback', component: AuthCallbackComponent },
  {
    path: '', component: DefaultLayoutComponent,
    data: { title: '' }, children: [
      { path: 'home', component: HomeComponent },
      {
        path: 'quan-ly-dau-tu',
        loadChildren: () => import('../app/pages/quan-ly-dau-tu/quan-ly-dau-tu.module').then(m => m.QuanLyDauTuModule)
      },
      {
        path: 'cai-dat',
        loadChildren: () => import('../app/pages/cai-dat/cai-dat.module').then(m => m.CaiDatModule)
      },
      {
        path: 'truy-van',
        loadChildren: () => import('../app/pages/truy-van/truy-van.module').then(m => m.TruyVanModule)
      },
      {
        path: 'quan-ly-hop-dong',
        loadChildren: () => import('../app/pages/quan-ly-hop-dong/quan-ly-hop-dong.module').then(m => m.QuanLyHopDongModule)
      },
      {
        path: 'bao-cao',
        loadChildren: () => import('../app/pages/bao-cao/bao-cao.module').then(m => m.BaoCaoModule)
      },
      {
        path: 'chinh-sach',
        loadChildren: () => import('../app/pages/chinh-sach/chinh-sach.module').then(m => m.ChinhSachModule)
      }
    ], canActivate: [AuthGuardService]
  },
  
  // {
  //   path: 'pos', component: DefaultLayoutPosComponent,
  //   data: { title: '' }, children: [
  //     { path: 'home', component: HomeComponent },
  //     {
  //       path: 'danh-muc',
  //       loadChildren: () => import('../app/components/danh-muc/danh-muc.module').then(m => m.DanhMucModule)
  //     },
     
  //   ], canActivate: [AuthGuardService]
  // }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: "legacy" })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
