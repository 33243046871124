import { Component, OnInit } from '@angular/core';
import { UserManager } from 'oidc-client';
import {Event, RouterEvent, Router, NavigationEnd} from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';
import { filter } from 'rxjs/operators';
import { menus } from './sidebar-routes.config';
// import { menus } from './sidebar-routes.config';
declare var $: any;
@Component({
    // moduleId: module.id,
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
})

export class SidebarComponent implements OnInit {
    menuItems: any[] = [];
    private manager: UserManager = new UserManager(environment.authenSettings);
    constructor (
        private router: Router,
        private authService: AuthService,
        private apiService: ApiService,
    ) {


        this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: any) => {
            const pathname  = window.location.pathname ;
            if(this.menuItems.length > 0) {
                this.parseObjectProperties(this.menuItems, event.url);
                this.menuItems = [...this.menuItems];
                localStorage.setItem('menuItems', JSON.stringify(this.menuItems));
            }else {
                this.getListMenuByUserId(pathname)
            }
      });
    }

    ngOnInit() {
        const pathname  = window.location.pathname ;
        // this.getListMenuByUserId(pathname)
    }

    getListMenuByUserId(pathname) {
        this.apiService.getListMenuByUserId(this.authService.getClaims().sub, '7af9293e-0a78-4b53-ae0e-288abbf7e844').subscribe(results => {
            this.menuItems = results.data.filter(menuItem => menuItem);
            this.parseObjectProperties(this.menuItems, pathname);
            localStorage.setItem('menuItems', JSON.stringify(results.data));
            this.menuItems = [...this.menuItems];
        });
    }

    parseObjectProperties(obj: any[], pathname) {
        for (let k of obj) {
            k.label = k.title;
            if (k.path && k.classs !== 'navigation-header') {
                k.routerLink = k.path;
                k.icon = ''
            }
            if (k.submenus && k.submenus.length > 0) {
                k.items = k.submenus.filter((d: any) => d.classs && (d.classs.indexOf("hidden") < 0));
            }
            if (k.routerLink) {
                // active menu con
                if(k.isExternalLink) {
                    if (k.routerLink && pathname.includes(k.routerLink)) {
                        k.styleClass = 'parent_active'
                    } else {
                        k.styleClass = 'parent_no_active'
                    }
                }else {
                    if (k.routerLink && pathname.includes(k.routerLink)) {
                        k.styleClass = 'active'
                    } else {
                        k.styleClass = 'no-active'
                    }
                }
               
            } else {
                //active cha
                if (k.badgeClass && pathname && pathname.split('/').indexOf(k.badgeClass) > -1 && k.classs === 'navigation-header') {
                    k.styleClass = "parent_active"
                } else {
                    k.styleClass = "parent_no_active"
                }
            }

            if (k.hasOwnProperty('items') && Array.isArray(k.items) && k.items.length > 0) {
                this.parseObjectProperties(k.items, pathname);
            }
        }
    }


}
