import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {AuthService} from './auth.service';
import {Observable} from 'rxjs';

const apiKSFBaseUrl = environment.apiKSFBase;
const apiCoreBaseUrl = environment.apiCoreBase;
const apiKSFServer = environment.apiKSFBase;
const apiUploadS3Url = environment.apiUploadS3Base;

@Injectable()
export class ApiService {
  optionsUpload = {};
  constructor(
    private http: HttpClient,
    private authService: AuthService) {
    this.optionsUpload = {
      headers: new HttpHeaders({
        Authorization: this.authService.getAuthorizationHeaderValue(),
        'X-Role-Token': localStorage.hasOwnProperty('md5') && localStorage.getItem('md5') ? localStorage.getItem('md5') : ''
      })
    };
  }

  options = {
    headers: new HttpHeaders({
      Authorization: this.authService.getAuthorizationHeaderValue(),
      'Content-Type': 'application/json',
      'X-Prod-Type': 'homepremier',
    })
  };

  apiTesst(): Observable<any> {
    return this.http
      .get<any>(`https://printer.sunshinegroup.vn:8080/api/printer`);
  }

  // Mẫu thông báo UInvTemplateNotify

  getTemplateNotifyPage(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/owner/GetTemplateNotifyPage?` + queryParams
      , this.options);
  }

  getTemplateNotifyInfo(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/owner/GetTemplateNotifyInfo?` + queryParams
      , this.options);
  }

  setTemplateNotifyInfo(queryParams): Observable<any> {
    return this.http.post(`${apiKSFBaseUrl}/api/v2/owner/SetTemplateNotifyInfo`, queryParams
      , this.options);
  }

  delTemplateNotifyInfo(queryParams): Observable<any> {
    return this.http.delete(`${apiKSFBaseUrl}/api/v2/owner/DelTemplateNotifyInfo?` + queryParams
      , this.options);
  }


  // mẫu thông báo hợp đồng
  getTemplateContractPage(params): Observable<any> {
    return this.http.get<any>(`${apiKSFServer}/api/v2/templatecontract/GetTemplateContractPage?` + params, this.options)
  }

  getTemplateContractInfo(params): Observable<any> {
    return this.http.get<any>(`${apiKSFServer}/api/v2/templatecontract/GetTemplateContractInfo?` + params, this.options)
  }

  delTemplateContractInfo(params): Observable<any> {
    return this.http.delete<any>(`${apiKSFServer}/api/v2/templatecontract/DelTemplateContractInfo?` + params, this.options)
  }

  setTemplateContractInfo(queryParams: any): Observable<any> {
    return this.http.post(`${apiKSFServer}/api/v2/templatecontract/SetTemplateContractInfo`, queryParams, this.optionsUpload);
  }

  uploadFileContract(queryParams: any): Observable<any> {
    return this.http.post(`${apiKSFServer}/api/v2/templatecontract/UploadFileContract`, queryParams, this.options);
  }

  setTemplateContractFieldExport(): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/templatecontract/setTemplateContractFieldExport`, {
      headers: new HttpHeaders({
        Authorization: this.authService.getAuthorizationHeaderValue(),
      }),
      responseType: 'blob'
    });
  }


  // ksInvSchemeTemplate

  getSchemeTemplateFilter(): Observable<any> {
    return this.http.get<any>(`${apiKSFServer}/api/v2/schemetemplate/GetSchemeTemplateFilter`, this.options)
  }

  getSchemeTemplatePage(params): Observable<any> {
    return this.http.get<any>(`${apiKSFServer}/api/v2/schemetemplate/GetSchemeTemplatePage?`+ params, this.options)
  }

  getSchemeTemplateInfo(params): Observable<any> {
    return this.http.get<any>(`${apiKSFServer}/api/v2/schemetemplate/GetSchemeTemplateInfo?`+ params, this.options)
  }

  setSchemeTemplateInfo(params): Observable<any> {
    return this.http.post<any>(`${apiKSFServer}/api/v2/schemetemplate/SetSchemeTemplateInfo`, params, this.options)
  }

  delSchemeTemplateInfo(params): Observable<any> {
    return this.http.delete<any>(`${apiKSFServer}/api/v2/schemetemplate/DelSchemeTemplateInfo?`+ params, this.options)
  }

  getSchemeTemplateList(): Observable<any> {
    return this.http.get<any>(`${apiKSFServer}/api/v2/schemetemplate/GetSchemeTemplateList`, this.options)
  }




  // UInvWork


  getParameterPage(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/work/GetParameterPage?` + queryParams, this.options);
  }

  getCfgCommonPage(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v1/config/GetCfgCommonPage?` + queryParams
      , this.options);
  }

  setCfgCommon(queryParams): Observable<any> {
    return this.http.post(`${apiKSFBaseUrl}/api/v1/config/SetCfgCommon`, queryParams, this.options);
  }

  getCfgCommon(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v1/config/GetCfgCommon?` + queryParams
      , this.options);
  }

  getInvParameter(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/work/getInvParameter?` + queryParams, this.options);
  }

  setInvParameter(queryParams): Observable<any> {
    return this.http.post(`${apiKSFBaseUrl}/api/v2/work/setInvParameter`, queryParams, this.options);
  }

  getCustObjectList(queryParams): Observable<any> {
    return this.http.get<any>(`${apiKSFBaseUrl}/api/v2/work/GetObjectList?` + queryParams, this.options);
  }

  getWorkflowPage(queryParams): Observable<any> {
    return this.http.get<any>(`${apiKSFBaseUrl}/api/v2/work/GetWorkflowPage?` + queryParams, this.options);
  }

  getWorkflowInfo(queryParams): Observable<any> {
    return this.http.get<any>(`${apiKSFBaseUrl}/api/v2/work/GetWorkflowInfo?` + queryParams, this.options);
  }

  setWorkSubmit(queryParams): Observable<any> {
    return this.http.post<any>(`${apiKSFBaseUrl}/api/v2/work/SetWorkSubmit`, queryParams, this.options);
  }

  setWorkApprove(queryParams): Observable<any> {
    return this.http.put<any>(`${apiKSFBaseUrl}/api/v2/work/SetWorkApprove`, queryParams, this.options);
  }

  getIntroPage(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/work/GetIntroPage?` + queryParams, this.options);
  }

  getIntroInfo(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/work/GetIntroInfo?` + queryParams, this.options);
  }

  getHolidayPage(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/work/GetHolidayPage?` + queryParams, this.options);
  }

  delHoliday(queryParams): Observable<any> {
    return this.http.delete(`${apiKSFBaseUrl}/api/v2/work/DelHoliday?` + queryParams, this.options);
  }

  setHolidayAdd(queryParams): Observable<any> {
    return this.http.post(`${apiKSFBaseUrl}/api/v2/work/SetHolidayAdd`, queryParams, this.options);
  }

  setHolidayCreate(queryParams): Observable<any> {
    return this.http.post(`${apiKSFBaseUrl}/api/v2/work/SetHolidayCreate`, queryParams, this.options);
  }

  getObjectList(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/work/GetObjectList?` + queryParams, this.options);
  }

  delIntroInfo(queryParams): Observable<any> {
    return this.http.delete(`${apiKSFBaseUrl}/api/v2/work/DelIntroInfo?` + queryParams, this.options);
  }


  // UInvProduct

  getProductPage(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/product/GetProductPage?` + queryParams, this.options);
  }

  getProducInfo(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/product/GetProducInfo?` + queryParams, this.options);
  }

  getProdFilter(): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/product/GetProdFilter`, this.options);
  }

  getProductList(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/product/GetProductList?` + queryParams, this.options);
  }

  getProductOrderPage(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/product/GetProductOrderPage?` + queryParams, this.options);
  }

  getProjectList(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/product/GetProjectList?` + queryParams, this.options);
  }

  getChannelList(): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/work/GetChannelList`, this.options);
  }

  delProduct(queryParams): Observable<any> {
    return this.http.delete(`${apiKSFBaseUrl}/api/v2/product/DelProduct?` + queryParams, this.options);
  }

  setProductCancel(queryParams): Observable<any> {
    return this.http.delete(`${apiKSFBaseUrl}/api/v2/product/SetProductCancel?` + queryParams, this.options);
  }

  setProductInfo(queryParams): Observable<any> {
    return this.http.post(`${apiKSFBaseUrl}/api/v2/product/SetProductInfo`, queryParams, this.options);
  }

  setProductSubmit(queryParams): Observable<any> {
    return this.http.put(`${apiKSFBaseUrl}/api/v2/product/SetProductSubmit`, queryParams, this.options);
  }

  //UInvOwner

  getOwnerList(): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/owner/GetOwnerList`, this.options);
  }

  getOwnerPage(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/owner/GetOwnerPage?` + queryParams, this.options);
  }

  getOwnerInfo(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/owner/GetOwnerInfo?` + queryParams, this.options);
  }

  setOwnerInfo(params): Observable<any> {
    return this.http.post(`${apiKSFBaseUrl}/api/v2/owner/SetOwnerInfo`, params, this.options);
  }

  setOwnerStatus(params): Observable<any> {
    return this.http.put(`${apiKSFBaseUrl}/api/v2/owner/SetOwnerStatus`, params, this.options);
  }

  delOwnerInfo(queryParams): Observable<any> {
    return this.http.delete(`${apiKSFBaseUrl}/api/v2/owner/DelOwnerInfo?` + queryParams, this.options);
  }

  // UInvOpen

  getOpenPage(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/open/GetOpenPage?` + queryParams, this.options);
  }

  getOpenFilter(): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/open/GetOpenFilter`, this.options);
  }

  getOpenInfo(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/open/GetOpenInfo?` + queryParams, this.options);
  }

  setOpenInfo(params): Observable<any> {
    return this.http.post(`${apiKSFBaseUrl}/api/v2/open/SetOpenInfo`, params, this.options);
  }

  delOpenInfo(params): Observable<any> {
    return this.http.delete(`${apiKSFBaseUrl}/api/v2/open/DelOpenInfo?` + params, this.options);
  }

  setOpenStatus(params): Observable<any> {
    return this.http.put(`${apiKSFBaseUrl}/api/v2/open/SetOpenStatus`, params, this.options);
  }

  getOpenDetail(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/open/GetOpenDetail?` + queryParams, this.options);
  }

  getOpenPolicyPage(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/open/GetOpenPolicyPage?` + queryParams, this.options);
  }

  getOpenVoucherPage(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/openvoucher/GetOpenVoucherPage?` + queryParams, this.options);
  }

  getOpenVoucher(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/openvoucher/GetOpenVoucher?` + queryParams, this.options);
  }

  delOpenVoucher2(queryParams): Observable<any> {
    return this.http.delete(`${apiKSFBaseUrl}/api/v2/openvoucher/DelOpenVoucher?` + queryParams, this.options);
  }

  setOpenVoucher(queryParams): Observable<any> {
    return this.http.post(`${apiKSFBaseUrl}/api/v2/openvoucher/SetOpenVoucher`, queryParams, this.options);
  }

  getOpenConvertPageNew(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/openconvert/GetOpenConvertPage?` + queryParams, this.options);
  }

  getConvertDiscountPage(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/schemeconvert/GetConvertDiscountPage?` + queryParams, this.options);
  }

  getOpenConvert(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/openconvert/GetOpenConvert?` + queryParams, this.options);
  }

  setOpenConvert(queryParams): Observable<any> {
    return this.http.post(`${apiKSFBaseUrl}/api/v2/openconvert/SetOpenConvert`, queryParams, this.options);
  }

  getOpenSchemePage(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/openscheme/GetOpenSchemePage?` + queryParams, this.options);
  }

  getOpenScheme(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/openscheme/GetOpenScheme?` + queryParams, this.options);
  }

  getOpenRoomPage(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/openroom/GetOpenRoomPage?` + queryParams, this.options);
  }

  getOpenRoomSalePage(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/openroom/GetOpenRoomSalePage?` + queryParams, this.options);
  }

  // getOpenScheme(queryParams): Observable<any> {
  //   return this.http.get(`${apiKSFBaseUrl}/api/v2/open/GetOpenScheme?` + queryParams, this.options);
  // }

  getOpenTenor(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/openscheme/getOpenTenor?` + queryParams, this.options);
  }

  delOpenTenor(queryParams): Observable<any> {
    return this.http.delete(`${apiKSFBaseUrl}/api/v2/openscheme/DelOpenTenor?` + queryParams, this.options);
  }

  delOpenScheme(queryParams): Observable<any> {
    return this.http.delete(`${apiKSFBaseUrl}/api/v2/openscheme/DelOpenScheme?` + queryParams, this.options);
  }

  delOpenDiscount(queryParams): Observable<any> {
    return this.http.delete(`${apiKSFBaseUrl}/api/v2/openconvert/DelOpenDiscount?` + queryParams, this.options);
  }

  delOpenVoucher(queryParams): Observable<any> {
    return this.http.delete(`${apiKSFBaseUrl}/api/v2/open/DelOpenVoucher?` + queryParams, this.options);
  }

  delOpenConvert(queryParams): Observable<any> {
    return this.http.delete(`${apiKSFBaseUrl}/api/v2/openconvert/DelOpenConvert?` + queryParams, this.options);
  }

  delOpenPolicy(queryParams): Observable<any> {
    return this.http.delete(`${apiKSFBaseUrl}/api/v2/open/DelOpenPolicy?` + queryParams, this.options);
  }

  setOpenTenor(queryParams): Observable<any> {
    return this.http.post(`${apiKSFBaseUrl}/api/v2/openscheme/SetOpenTenor`, queryParams, this.options);
  }

  setOpenScheme(urlApiSave, queryParams): Observable<any> {
    return this.http.post(`${apiKSFBaseUrl}/api/v2/openscheme/${urlApiSave}`, queryParams, this.options);
  }

  setOpenconvert(urlApiSave, queryParams): Observable<any> {
    return this.http.post(`${apiKSFBaseUrl}/api/v2/openconvert/${urlApiSave}`, queryParams, this.options);
  }

  getOpenList(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/open/GetOpenList?` + queryParams, this.options);
  }

  getConsultantReferral(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/orderagent/GetConsultantReferral?` + queryParams, this.options);
  }

  getConsultantPage(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/orderagent/GetConsultantPage?` + queryParams, this.options);
  }

  setOpenSubmit(queryParams): Observable<any> {
    return this.http.put(`${apiKSFBaseUrl}/api/v2/open/SetOpenSubmit`, queryParams, this.options);
  }

  getOpenVoucherList(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/open/GetOpenVoucherList?${queryParams}`, this.options);
  }

  getOpenSchemes(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/open/GetOpenSchemes?${queryParams}`, this.options);
  }

  getOpenSchPayPage(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/open/GetOpenSchPayPage?${queryParams}`, this.options);
  }

  getOpenSchPay(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/open/GetOpenSchPay?${queryParams}`, this.options);
  }

  delOpenSchPay(queryParams: any): Observable<any> {
    return this.http.delete(`${apiKSFServer}/api/v2/open/DelOpenSchPay?${queryParams}`, this.options);
  }

  setOpenSchPay(queryParams: any): Observable<any> {
    return this.http.post(`${apiKSFBaseUrl}/api/v2/open/SetOpenSchPay`, queryParams, this.options);
  }

  //UInvSchemeVoucher

  getSchemeVoucherFilter(): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/schemevoucher/GetSchemeVoucherFilter`
      , this.options);
  }

  getSchemeVoucherPage(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/schemevoucher/GetSchemeVoucherPage?${queryParams}`
      , this.options);
  }

  getSchemeVoucherInfo(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/schemevoucher/GetSchemeVoucherInfo?${queryParams}`
      , this.options);
  }

  setSchemeVoucherInfo(queryParams: any): Observable<any> {
    return this.http.post(`${apiKSFServer}/api/v2/schemevoucher/SetSchemeVoucherInfo`, queryParams
      , this.options);
  }

  setSchemeVoucherSubmit(queryParams: any): Observable<any> {
    return this.http.put(`${apiKSFServer}/api/v2/schemevoucher/SetSchemeVoucherSubmit`, queryParams
      , this.options);
  }

  setSchemeVoucherStatus(queryParams: any): Observable<any> {
    return this.http.put(`${apiKSFServer}/api/v2/schemevoucher/SetSchemeVoucherStatus`, queryParams
      , this.options);
  }

  delSchemeVoucherInfo(queryParams: any): Observable<any> {
    return this.http.delete(`${apiKSFServer}/api/v2/schemevoucher/DelSchemeVoucherInfo?` + queryParams
      , this.options);
  }

  getSchemeVoucherList(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/schemevoucher/GetSchemeVoucherList?` + queryParams
      , this.options);
  }


  // UInvConfig

  getFormViewPage(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v1/config/GetFormViewPage?` + queryParams, this.options);
  }

  getGroupInfo(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v1/config/GetGroupInfo?` + queryParams, this.options);
  }

  setGroupInfo(queryParams): Observable<any> {
    return this.http.post(`${apiKSFBaseUrl}/api/v1/config/SetGroupInfo`, queryParams, this.options);
  }

  getGridViewPage(url, queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v1/config/${url}?` + queryParams, this.options);
  }

  setFormViewInfo(queryParams): Observable<any> {
    return this.http.post(`${apiKSFBaseUrl}/api/v1/config/SetFormViewInfo`, queryParams, this.options);
  }

  setGridViewInfo(url, queryParams): Observable<any> {
    return this.http.post(`${apiKSFBaseUrl}/api/v1/config/${url}`, queryParams, this.options);
  }

  delFormViewInfo(url, queryParams): Observable<any> {
    return this.http.delete(`${apiKSFBaseUrl}/api/v1/config/${url}?` + queryParams, this.options);
  }

  delGridViewInfo(queryParams): Observable<any> {
    return this.http.delete(`${apiKSFBaseUrl}/api/v2/config/DelGridViewInfo?` + queryParams, this.options);
  }


  //UInvCooperatorShow

  getCooperatorPage(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/cooperator/GetCooperatorPage?` + queryParams, this.options);
  }

  getCooperatorFilter(): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/cooperator/GetCooperatorFilter`, this.options);
  }

  getCooperatorInfo(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/cooperator/GetCooperatorInfo?` + queryParams, this.options);
  }

  delCooperatorInfo(queryParams): Observable<any> {
    return this.http.delete(`${apiKSFBaseUrl}/api/v2/cooperator/DelCooperatorInfo?` + queryParams, this.options);
  }

  setCooperatorInfo(queryParams): Observable<any> {
    return this.http.post(`${apiKSFBaseUrl}/api/v2/cooperator/SetCooperatorInfo`, queryParams, this.options);
  }

  setOrderContInfo(queryParams): Observable<any> {
    return this.http.post(`${apiKSFBaseUrl}/api/v2/ordercont/SetOrderContInfo`, queryParams, this.options);
  }

  setCooperatorStatus(queryParams): Observable<any> {
    return this.http.put(`${apiKSFBaseUrl}/api/v2/cooperator/SetCooperatorStatus`, queryParams, this.options);
  }

  getCooperatorList(queryParams = null): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/cooperator/GetCooperatorList?` + queryParams, this.options);
  }

  // UInvTransaction

  getTransFilter(): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/transaction/GetTransFilter`, this.options);
  }

  getOrderContFilter(): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/ordercont/GetOrderContFilter`, this.options);
  }

  setTransactionApprove(queryParams): Observable<any> {
    return this.http.put(`${apiKSFBaseUrl}/api/v2/transaction/SetTransactionApprove`, queryParams, this.options);
  }

  setNoPayApprovedOfFinal(queryParams): Observable<any> {
    return this.http.put(`${apiKSFBaseUrl}/api/v2/final/SetNoPayApprovedOfFinal`, queryParams, this.options);
  }

  setPayOnHalfApprove(queryParams): Observable<any> {
    return this.http.put(`${apiKSFBaseUrl}/api/v2/transaction/SetPayOnHalfApprove`, queryParams, this.options);
  }

  setTransactionInfo(queryParams): Observable<any> {
    return this.http.post(`${apiKSFBaseUrl}/api/v2/transaction/SetTransactionInfo`, queryParams, this.options);
  }

  setOrderPaymentApprove(queryParams): Observable<any> {
    return this.http.post(`${apiKSFBaseUrl}/api/v2/order/SetOrderPaymentApprove`, queryParams, this.options);
  }

  setTransactionNotify(queryParams): Observable<any> {
    return this.http.post(`${apiKSFBaseUrl}/api/v2/transaction/SetTransactionNotify`, queryParams, this.options);
  }

  getTransactionPage(queryParams): Observable<any> {
    return this.http.put(`${apiKSFBaseUrl}/api/v2/transaction/GetTransactionPage`, queryParams, this.options);
  }

  getTransactionInfo(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/transaction/GetTransactionInfo?` + queryParams, this.options);
  }

  getTransBankPage(queryParams): Observable<any> {
    return this.http.put(`${apiKSFBaseUrl}/api/v2/transaction/GetTransBankPage`, queryParams, this.options);
  }

  getTransBankPayonPage(queryParams): Observable<any> {
    return this.http.put(`${apiKSFBaseUrl}/api/v2/transaction/GetTransBankPayonPage`, queryParams, this.options);
  }

  getTransAccounts(): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/transaction/GetTransAccounts`, this.options);
  }

  getTransBankAccountPage(queryParams): Observable<any> {
    return this.http.put(`${apiKSFBaseUrl}/api/v2/transaction/GetTransBankAccountPage`, queryParams, this.options);
  }

  delTransaction(queryParams): Observable<any> {
    return this.http.delete(`${apiKSFBaseUrl}/api/v2/transaction/DelTransaction?` + queryParams, this.options);
  }

  setTransactionCancel(queryParams): Observable<any> {
    return this.http.delete(`${apiKSFBaseUrl}/api/v2/transaction/SetTransactionCancel?` + queryParams, this.options);
  }

  // UInvExchange

  setExchange(queryParams): Observable<any> {
    return this.http.post(`${apiKSFBaseUrl}/api/v2/exchange/SetExchange`, queryParams, this.options);
  }

  setExchangeApprove(queryParams): Observable<any> {
    return this.http.put(`${apiKSFBaseUrl}/api/v2/exchange/SetExchangeApprove`, queryParams, this.options);
  }

  setExchangeCancel(queryParams): Observable<any> {
    return this.http.put(`${apiKSFBaseUrl}/api/v2/exchange/SetExchangeCancel`, queryParams, this.options);
  }

  setExchangeBuyConfirm(queryParams): Observable<any> {
    return this.http.put(`${apiKSFBaseUrl}/api/v2/exchange/SetExchangeBuyConfirm`, queryParams, this.options);
  }

  setExhangeVerify(queryParams): Observable<any> {
    return this.http.put(`${apiKSFBaseUrl}/api/v2/exchange/SetExchangeVerify`, queryParams, this.options);
  }

  getOrderExchangePage(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/order/GetOrderExchangePage?` + queryParams, this.options);
  }

  setExchangeConfirm(queryParams): Observable<any> {
    return this.http.put(`${apiKSFBaseUrl}/api/v2/exchange/SetExchangeConfirm`, queryParams, this.options);
  }

  getExchangePage(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/exchange/GetExchangePage?` + queryParams, this.options);
  }

  getExchangeInfo(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/exchange/GetExchangeInfo?` + queryParams, this.options);
  }

  delExchange(queryParams): Observable<any> {
    return this.http.delete(`${apiKSFBaseUrl}/api/v2/exchange/DelExchange?` + queryParams, this.options);
  }

  getListMenuByUserId(userId, webId): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        Authorization: this.authService.getAuthorizationHeaderValue(),
        'Content-Type': 'application/json',
      })
    };
    return this.http.get<any>(`${apiCoreBaseUrl}/api/v1/webmanager/ClientMenuGetListByUserId?` +
      `userId=${userId}&webId=${webId}`, options)
  }


  //UInvOrderBook

  getOrderBookPage(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/book/GetOrderBookPage?${queryParams}`, this.options);
  }

  getOrderFilter(queryParams): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/order/GetOrderFilter?${queryParams}`, this.options);
  }

  getOrderRoomPage(queryParams): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/order/getOrderRoomPage?${queryParams}`, this.options);
  }

  getOrderSchePayPage(queryParams): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/order/GetOrderSchePayPage?${queryParams}`, this.options);
  }

  getOrderBookInfo(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/book/GetOrderBookInfo?${queryParams}`, this.options);
  }

  setOrderBookDraft(queryParams: any): Observable<any> {
    return this.http.post(`${apiKSFServer}/api/v2/book/SetOrderBookDraft`, queryParams, this.options);
  }

  setOrderBookInfo(queryParams: any): Observable<any> {
    return this.http.post(`${apiKSFServer}/api/v2/book/SetOrderBookInfo`, queryParams, this.options);
  }

  delOrderBook(queryParams: any): Observable<any> {
    return this.http.delete(`${apiKSFServer}/api/v2/book/DelOrderBook?${queryParams}`, this.options);
  }

  setOrderBookStatus(params: any): Observable<any> {
    return this.http.post(`${apiKSFServer}/api/v2/book/SetOrderBookStatus`, params, this.options);
  }


  //UInvOrder
  setOrderLockNotify(params: any): Observable<any> {
    return this.http.post(`${apiKSFServer}/api/v2/order/SetOrderLockNotify`, params, this.options);
  }

  setOrderMetaUpload(params: any): Observable<any> {
    return this.http.post(`${apiKSFServer}/api/v2/order/SetOrderMetaUpload`, params, this.options);
  }

  setContract(params: any): Observable<any> {
    return this.http.put(`${apiKSFServer}/api/v2/order/SetContract`, params, this.options);
  }

  setOrderUpOnline(params: any): Observable<any> {
    return this.http.put(`${apiKSFServer}/api/v2/order/SetOrderUpOnline`, params, this.options);
  }

  setOrderVoucher(params: any): Observable<any> {
    return this.http.post(`${apiKSFServer}/api/v2/ordervoucher/setOrderVoucher`, params, this.options);
  }

  setSiptOrderApproveReg(params: any): Observable<any> {
    return this.http.post(`${apiKSFServer}/api/v2/order/SetOrderApproveReg`, params, this.options);
  }

  getOrderMetaPage(queryParams): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/order/GetOrderMetaPage?${queryParams}`, this.options);
  }

  getOrderHoldPage(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/orderhold/GetOrderHoldPage?${queryParams}`, this.options);
  }

  setOrderHold(params: any): Observable<any> {
    return this.http.post(`${apiKSFServer}/api/v2/orderhold/SetOrderHold`, params, this.options);
  }

  getContractFile(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/order/GetContractFile?${queryParams}`, this.options);
  }

  getOrderLinePage(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/order/getOrderLinePage?${queryParams}`, this.options);
  }

  getOrderFinance(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/order/GetOrderFinance?${queryParams}`, this.options);
  }

  getOrderHoldInfo(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/orderhold/GetOrderHoldInfo?${queryParams}`, this.options);
  }

  getOrderContInfo(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/ordercont/getOrderContInfo?${queryParams}`, this.options);
  }

  delOrderVoucher(queryParams: any): Observable<any> {
    return this.http.delete(`${apiKSFServer}/api/v2/ordervoucher/DelOrderVoucher?${queryParams}`, this.options);
  }

  getOrderVoucher(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/ordervoucher/GetOrderVoucher?${queryParams}`, this.options);
  }

  // chi lãi bổ sung -setOrderWithdrawalFinalDraft(rut von qua han)
  getWithdrawalInterestPage(params): Observable<any> {
    return this.http.get<any>(`${apiKSFServer}/api/v2/withdrawal/GetWithdrawalInterestPage?` + params, this.options)
  }

  // Duyệt chi tiền
  setPayOnHalfOfWithdrawalInterest(queryParams: any): Observable<any> {
    return this.http.put(`${apiKSFServer}/api/v2/withdrawal/SetPayOnHalfOfWithdrawalInterest`, queryParams, this.options);
  }

  // Duyệt không chi
  setNoPayApprovedOfWithdrawalInterest(queryParams: any): Observable<any> {
    return this.http.put(`${apiKSFServer}/api/v2/withdrawal/SetNoPayApprovedOfWithdrawalInterest`, queryParams, this.options);
  }

  getOrderVoucherPage(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/ordervoucher/GetOrderVoucherPage?${queryParams}`, this.options);
  }

  delOrderMetaUpload(queryParams: any): Observable<any> {
    return this.http.delete(`${apiKSFServer}/api/v2/order/DelOrderMetaUpload?${queryParams}`, this.options);
  }

  getOrderPage(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/order/GetOrderPage?${queryParams}`, this.options);
  }

  getOrderContPage(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/ordercont/GetOrderContPage?${queryParams}`, this.options);
  }

  getVoucherPage(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/ordervoucher/GetVoucherPage?${queryParams}`, this.options);
  }

  getVoucherFilter(): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/ordervoucher/GetVoucherFilter`, this.options);
  }

  getOrderContDelive(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/ordercont/GetOrderContDelive?${queryParams}`, this.options);
  }

  setOrderBook(queryParams: any): Observable<any> {
    return this.http.post(`${apiKSFServer}/api/v2/order/SetOrderBook`, queryParams, this.options);
  }

  setOrderContReceive(queryParams: any): Observable<any> {
    return this.http.post(`${apiKSFServer}/api/v2/ordercont/SetOrderContReceive`, queryParams, this.options);
  }

  setOrderContDelive(queryParams: any): Observable<any> {
    return this.http.post(`${apiKSFServer}/api/v2/ordercont/SetOrderContDelive`, queryParams, this.options);
  }

  setOrderUpgate(queryParams: any): Observable<any> {
    return this.http.put(`${apiKSFServer}/api/v2/order/SetOrderUpgate`, queryParams, this.options);
  }

  getOrderInfo(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/order/GetOrderInfo?${queryParams}`, this.options);
  }

  getOrderFlow(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/order/GetOrderFlow?${queryParams}`, this.options);
  }

  setOrderNotify(queryParams: any): Observable<any> {
    return this.http.post(`${apiKSFServer}/api/v2/order/setOrderNotify`, queryParams, this.options);
  }

  getOrderLiquidationPage(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/order/GetOrderLiquidationPage?` + queryParams, this.options);
  }

  setOrderRelease(queryParams: any): Observable<any> {
    return this.http.post(`${apiKSFServer}/api/v2/orderhold/SetOrderRelease`, queryParams, this.options);
  }

  getOrderRelease(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/orderhold/GetOrderRelease?` + queryParams, this.options);
  }

  setOrderPaymentRollback(queryParams: any): Observable<any> {
    return this.http.post(`${apiKSFServer}/api/v2/order/SetOrderPaymentRollback`, queryParams, this.options);
  }

  setOrderNotifyPay(queryParams: any): Observable<any> {
    return this.http.post(`${apiKSFServer}/api/v2/order/SetOrderNotifyPay`, queryParams, this.options);
  }

  setOrderCancel(queryParams: any): Observable<any> {
    return this.http.post(`${apiKSFServer}/api/v2/order/SetOrderCancel`, queryParams, this.options);
  }

  setVoucherClean(queryParams: any): Observable<any> {
    return this.http.put(`${apiKSFServer}/api/v2/ordervoucher/SetVoucherClean`, queryParams, this.options);
  }

  setVoucherPlan(queryParams: any): Observable<any> {
    return this.http.put(`${apiKSFServer}/api/v2/ordervoucher/SetVoucherPlan`, queryParams, this.options);
  }

  setVoucherReceived(queryParams: any): Observable<any> {
    return this.http.put(`${apiKSFServer}/api/v2/ordervoucher/SetVoucherReceived`, queryParams, this.options);
  }

  setPayApproveOfVoucher(queryParams: any): Observable<any> {
    return this.http.put(`${apiKSFServer}/api/v2/ordervoucher/SetPayApproveOfVoucher`, queryParams, this.options);
  }

  setPayOnHalfOfVoucher(queryParams: any): Observable<any> {
    return this.http.put(`${apiKSFServer}/api/v2/ordervoucher/SetPayOnHalfOfVoucher`, queryParams, this.options);
  }

  delOrder(queryParams: any): Observable<any> {
    return this.http.delete(`${apiKSFServer}/api/v2/order/DelOrder?${queryParams}`, this.options);
  }

  //UInvInterest

  getInterestFilter(): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/interest/GetInterestFilter`, this.options);
  }

  getOrderInterestPage(queryParams: any): Observable<any> {
    return this.http.put(`${apiKSFServer}/api/v2/interest/GetOrderInterestPage`, queryParams, this.options);
  }

  setOrderInterestPlan(queryParams: any): Observable<any> {
    return this.http.put(`${apiKSFServer}/api/v2/interest/SetOrderInterestPlan`, queryParams, this.options);
  }

  setPayOnHalfOfInterest(queryParams: any): Observable<any> {
    return this.http.put(`${apiKSFServer}/api/v2/interest/SetPayOnHalfOfInterest`, queryParams, this.options);
  }

  setNoPayOfInterest(queryParams: any): Observable<any> {
    return this.http.put(`${apiKSFServer}/api/v2/interest/SetNoPayOfInterest`, queryParams, this.options);
  }

  //UInvWithdrawal

  getWithdrawalFilter(): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/withdrawal/GetWithdrawalFilter`, this.options);
  }

  getTransBankFilter(): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/transaction/GetTransBankFilter`, this.options);
  }

  getTransPayonFilter(): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/transaction/GetTransPayonFilter`, this.options);
  }


  getOrderWithdrawalPage(queryParams: any): Observable<any> {
    return this.http.put(`${apiKSFServer}/api/v2/withdrawal/GetOrderWithdrawalPage`, queryParams, this.options);
  }

  getOrderWithdrawalFinalPage(queryParams: any): Observable<any> {
    return this.http.put(`${apiKSFServer}/api/v2/withdrawal/GetOrderWithdrawalFinalPage`, queryParams
      , this.options);
  }

  getOrderWithdrawalInfo(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/withdrawal/GetOrderWithdrawalInfo?${queryParams}`, this.options);
  }

  delWithdrawal(queryParams: any): Observable<any> {
    return this.http.delete(`${apiKSFServer}/api/v2/withdrawal/DelWithdrawal?${queryParams}`, this.options);
  }

  setOrderWithdrawalDraft(queryParams: any): Observable<any> {
    return this.http.put(`${apiKSFServer}/api/v2/withdrawal/SetOrderWithdrawalDraft`, queryParams, this.options);
  }

  setOrderWithdrawalInfo(queryParams: any): Observable<any> {
    return this.http.post(`${apiKSFServer}/api/v2/withdrawal/SetOrderWithdrawalInfo`, queryParams, this.options);
  }

  setOrderWithdrawalSet(queryParams: any): Observable<any> {
    return this.http.put(`${apiKSFServer}/api/v2/withdrawal/SetOrderWithdrawalSet`, queryParams, this.options);
  }

  setOrderWithdrawalFinalDraft(queryParams: any): Observable<any> {
    return this.http.put(`${apiKSFServer}/api/v2/withdrawal/SetOrderWithdrawalFinalDraft`, queryParams, this.options);
  }

  setOrderWithdrawalFinalSet(queryParams: any): Observable<any> {
    return this.http.put(`${apiKSFServer}/api/v2/withdrawal/SetOrderWithdrawalFinalSet`, queryParams, this.options);
  }


  //UInvLiquidation

  getLiquidationFilter(): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/liquidation/GetLiquidationFilter`, this.options);
  }

  getLiquidationInfo(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/liquidation/GetLiquidationInfo?${queryParams}`, this.options);
  }

  getLiquidationPage(queryParams: any): Observable<any> {
    return this.http.put(`${apiKSFServer}/api/v2/liquidation/GetLiquidationPage`, queryParams, this.options);
  }

  getOrderIntMethods(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/liquidation/GetOrderIntMethods?${queryParams}`, this.options);
  }

  delLiquidation(queryParams: any): Observable<any> {
    return this.http.delete(`${apiKSFServer}/api/v2/liquidation/DelLiquidation?${queryParams}`, this.options);
  }

  setLiquidationInfo(queryParams: any): Observable<any> {
    return this.http.post(`${apiKSFServer}/api/v2/liquidation/SetLiquidationInfo`, queryParams, this.options);
  }

  setLiquidationDraft(queryParams: any): Observable<any> {
    return this.http.put(`${apiKSFServer}/api/v2/liquidation/SetLiquidationDraft`, queryParams, this.options);
  }

  setLiquidationSet(queryParams: any): Observable<any> {
    return this.http.put(`${apiKSFServer}/api/v2/liquidation/SetLiquidationSet`, queryParams, this.options);
  }

  setLiquidationChange(queryParams: any): Observable<any> {
    return this.http.put(`${apiKSFServer}/api/v2/liquidation/SetLiquidationChange`, queryParams, this.options);
  }

  // UInvAgent

  getOrderAgentPage(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/orderagent/GetOrderAgentPage?${queryParams}`, this.options);
  }

  setOrderAgentCommit(queryParams: any): Observable<any> {
    return this.http.put(`${apiKSFServer}/api/v2/orderagent/SetOrderAgentCommit`, queryParams, this.options);
  }

  setPayOnHalfOfAgent(queryParams: any): Observable<any> {
    return this.http.put(`${apiKSFServer}/api/v2/orderagent/SetPayOnHalfOfAgent`, queryParams, this.options);
  }


  //UInvScheme

  getSchemePage(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/scheme/GetSchemePage?${queryParams}`, this.options);
  }

  getSchemeFilter(): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/scheme/GetSchemeFilter`, this.options);
  }

  getSchemeInfo(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/scheme/GetSchemeInfo?${queryParams}`, this.options);
  }

  getSchemeList(queryParams = null): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/scheme/GetSchemeList?` + queryParams, this.options);
  }

  getSchemeTenor(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/scheme/GetSchemeTenor?${queryParams}`, this.options);
  }

  getSchemeTenorList(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/scheme/GetSchemeTenorList?${queryParams}`, this.options);
  }

  getOpenTenorList(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/openscheme/GetOpenTenorList?${queryParams}`, this.options);
  }


  getConvertPage(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/schemeconvert/GetConvertPage?${queryParams}`, this.options);
  }

  getConvertFilter(): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/schemeconvert/GetConvertFilter`, this.options);
  }

  getConvertInfo(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/schemeconvert/GetConvertInfo?${queryParams}`, this.options);
  }

  getOpenTenorPage(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/openscheme/GetOpenTenorPage?${queryParams}`, this.options);
  }

  getOpenDiscountPage(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/openconvert/GetOpenDiscountPage?${queryParams}`, this.options);
  }

  getOpenDiscount(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/openconvert/GetOpenDiscount?${queryParams}`, this.options);
  }

  getConvertList(queryParams = null): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/schemeconvert/GetConvertList?` + queryParams, this.options);
  }

  getConvertDiscount(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/schemeconvert/GetConvertDiscount?${queryParams}`, this.options);
  }

  delSchemeInfo(queryParams: any): Observable<any> {
    return this.http.delete(`${apiKSFServer}/api/v2/scheme/DelSchemeInfo?${queryParams}`, this.options);
  }

  setSchemeStatus(queryParams: any): Observable<any> {
    return this.http.put(`${apiKSFServer}/api/v2/scheme/SetSchemeStatus`, queryParams, this.options);
  }

  setConvertStatus(queryParams: any): Observable<any> {
    return this.http.put(`${apiKSFServer}/api/v2/schemeconvert/SetConvertStatus`, queryParams, this.options);
  }

  delSchemeTenor(queryParams: any): Observable<any> {
    return this.http.delete(`${apiKSFServer}/api/v2/scheme/DelSchemeTenor?${queryParams}`, this.options);
  }

  delConvertInfo(queryParams: any): Observable<any> {
    return this.http.delete(`${apiKSFServer}/api/v2/schemeconvert/DelConvertInfo?${queryParams}`, this.options);
  }

  delConvertDiscount(queryParams: any): Observable<any> {
    return this.http.delete(`${apiKSFServer}/api/v2/schemeconvert/DelConvertDiscount?${queryParams}`, this.options);
  }

  setConvertDiscount(queryParams: any): Observable<any> {
    return this.http.put(`${apiKSFServer}/api/v2/schemeconvert/SetConvertDiscount`, queryParams, this.options);
  }

  setConvertSubmit(queryParams: any): Observable<any> {
    return this.http.put(`${apiKSFServer}/api/v2/schemeconvert/setConvertSubmit`, queryParams, this.options);
  }

  setSchemeInfo(queryParams: any): Observable<any> {
    return this.http.post(`${apiKSFServer}/api/v2/scheme/SetSchemeInfo`, queryParams, this.options);
  }

  setConvertInfo(queryParams: any): Observable<any> {
    return this.http.put(`${apiKSFServer}/api/v2/schemeconvert/SetConvertInfo`, queryParams, this.options);
  }

  setSchemeTenor(queryParams: any): Observable<any> {
    return this.http.post(`${apiKSFServer}/api/v2/scheme/SetSchemeTenor`, queryParams, this.options);
  }

  setSchemeSubmit(queryParams: any): Observable<any> {
    return this.http.put(`${apiKSFServer}/api/v2/scheme/SetSchemeSubmit`, queryParams, this.options);
  }

  getSchemeTenorPage(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/scheme/GetSchemeTenorPage?` + queryParams, this.options);
  }


  // UInvAgency

  getAgencyPolicyPage(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/agency/GetAgencyPolicyPage?${queryParams}`, this.options);
  }

  getAgencyPolicyInfo(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/agency/GetAgencyPolicyInfo?${queryParams}`, this.options);
  }

  getAgencyPolicyShare(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/agency/GetAgencyPolicyShare?${queryParams}`, this.options);
  }

  getAgencyPolicyConvert(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/agency/GetAgencyPolicyConvert?${queryParams}`, this.options);
  }

  getAgencyPolicyList(): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/agency/GetAgencyPolicyList`, this.options);
  }

  setAgencyPolicyInfo(queryParams: any): Observable<any> {
    return this.http.post(`${apiKSFServer}/api/v2/agency/SetAgencyPolicyInfo`, queryParams, this.options);
  }

  setAgencyPolicyConvert(queryParams: any): Observable<any> {
    return this.http.post(`${apiKSFServer}/api/v2/agency/SetAgencyPolicyConvert`, queryParams, this.options);
  }

  setAgencyPolicyShare(queryParams: any): Observable<any> {
    return this.http.put(`${apiKSFServer}/api/v2/agency/SetAgencyPolicyShare`, queryParams, this.options);
  }

  rejectAgencyPolicyInfo(queryParams: any): Observable<any> {
    return this.http.put(`${apiKSFServer}/api/v2/agency/RejectAgencyPolicyInfo`, queryParams, this.options);
  }

  delAgencyPolicyInfo(queryParams: any): Observable<any> {
    return this.http.delete(`${apiKSFServer}/api/v2/agency/DelAgencyPolicyInfo?${queryParams}`, this.options);
  }

  delAgencyPolicyConvert(queryParams: any): Observable<any> {
    return this.http.delete(`${apiKSFServer}/api/v2/agency/DelAgencyPolicyConvert?${queryParams}`, this.options);
  }

  delAgencyPolicyShare(queryParams: any): Observable<any> {
    return this.http.delete(`${apiKSFServer}/api/v2/agency/DelAgencyPolicyShare?${queryParams}`, this.options);
  }

  // ksInvConvert

  getOpenConvertPage(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/convert/GetOpenConvertPage?` + queryParams, this.options);
  }

  // UmeUser

  getUserPage(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v1/UmeUser/GetUserPage?${queryParams}`, this.options);
  }

  getCustPage(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v1/UmeUser/GetCustPage?${queryParams}`, this.options);
  }

  getUserProfileByIdcard(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v1/UmeUser/GetUserProfileByIdcard?${queryParams}`, this.options);
  }

  getUserInfo(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v1/UmeUser/GetUserInfo?${queryParams}`, this.options);
  }

  getCustInfo(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v1/UmeUser/GetCustInfo?${queryParams}`, this.options);
  }

  getUserProfile(): Observable<any> {
    return this.http.post(`${apiKSFServer}/api/v1/UmeUser/GetUserProfile`, this.options);
  }

  setUserProfile(queryParams): Observable<any> {
    return this.http.post(`${apiKSFServer}/api/v1/UmeUser/setUserProfile`, queryParams, this.options);
  }


  //UInvAccount


  getAccountPage(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/account/GetAccountPage?${queryParams}`, this.options);
  }

  getBankList(): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/account/GetBankList`, this.options);
  }

  getAccountInfo(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/account/GetAccountInfo?${queryParams}`, this.options);
  }

  delAccountInfo(queryParams: any): Observable<any> {
    return this.http.delete(`${apiKSFServer}/api/v2/account/DelAccountInfo?${queryParams}`, this.options);
  }

  setAccountInfo(queryParams: any): Observable<any> {
    return this.http.post(`${apiKSFServer}/api/v2/account/SetAccountInfo`, queryParams, this.options);
  }

  setAccountStatus(queryParams: any): Observable<any> {
    return this.http.post(`${apiKSFServer}/api/v2/account/SetAccountStatus`, queryParams, this.options);
  }

  getRoomQrs(queryParams: any): Observable<any> {
    return this.http.get(`https://apisre.sunshinetech.com.vn/api/v1/shousing/GetRoomQrs?` + queryParams);
  }

  //API GETINFO
  apiGetInfo(manhinh, url, queryParams): Observable<any> {
    if (manhinh === 'open') {
      return this.http.get(`${apiKSFBaseUrl}/api/v2/${url}?` + queryParams, this.options);
    } else {
      return this.http.get(`${apiKSFBaseUrl}/api/v2/product/${url}?` + queryParams, this.options);
    }
  }

  apiSetInfo(manhinh, url, queryParams): Observable<any> {
    if (manhinh === 'open') {
      return this.http.post(`${apiKSFBaseUrl}/api/v2/${url}`, queryParams, this.options);
    } else {
      return this.http.post(`${apiKSFBaseUrl}/api/v2/product/${url}`, queryParams, this.options);
    }
  }

  getRoomList(manhinh, queryParams): Observable<any> {
    if (manhinh === 'open') {
      return this.http.get<any>(`${apiKSFBaseUrl}/api/v2/openroom/GetRoomList?` + queryParams, this.options)
    } else {
      return this.http.get<any>(`${apiKSFBaseUrl}/api/v2/product/GetRoomList?` + queryParams, this.options)
    }
  }

  delOpenRoom(manhinh, queryParams): Observable<any> {
    if (manhinh === 'open') {
      return this.http.delete<any>(`${apiKSFBaseUrl}/api/v2/openroom/DelOpenRoom?` + queryParams, this.options)
    } else {
      return this.http.delete<any>(`${apiKSFBaseUrl}/api/v2/product/DelProdRoom?` + queryParams, this.options)
    }
  }

  setOpenRoomStatus(manhinh, queryParams): Observable<any> {
    if (manhinh === 'open') {
      return this.http.put<any>(`${apiKSFBaseUrl}/api/v2/openroom/SetOpenRoomStatus`, queryParams, this.options)
    } else {
      return this.http.put<any>(`${apiKSFBaseUrl}/api/v2/product/SetProdRoomStatus`, queryParams, this.options)
    }
  }

  setOpenRoomStatusOpen(queryParams): Observable<any> {
    return this.http.put<any>(`${apiKSFBaseUrl}/api/v2/openroom/SetOpenRoomStatus`, queryParams, this.options)
  }

  setOpenRoomSaled(queryParams): Observable<any> {
    return this.http.put<any>(`${apiKSFBaseUrl}/api/v2/openroom/SetOpenRoomSaled`, queryParams, this.options)
  }

  setOpenRoomAdds(manhinh, queryParams): Observable<any> {
    if (manhinh === 'open') {
      return this.http.post<any>(`${apiKSFBaseUrl}/api/v2/openroom/SetOpenRoomAdds`, queryParams, this.options)
    } else {
      return this.http.post<any>(`${apiKSFBaseUrl}/api/v2/product/SetProdRoomAdds`, queryParams, this.options)
    }
  }

  getProdRoomPage(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/product/GetProdRoomPage?` + queryParams, this.options);
  }

  // UInvReport

  getReportList(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/report/GetReportList?${queryParams}`, this.options);
  }

  getTemplateReportPage(queryParams: string, url: string): Observable<any> {
    return this.http.post<any>(`${url}`, queryParams, this.options)
  }



  // UInvFinal

  getFinalFilter(): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/final/GetFinalFilter`, this.options);
  }

  getOrderFinalPage(queryParams: any): Observable<any> {
    return this.http.put(`${apiKSFServer}/api/v2/final/GetOrderFinalPage`, queryParams, this.options);
  }

  getOrderFinalInfo(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/final/GetOrderFinalInfo?${queryParams}`, this.options);
  }

  getOrderHistoryPage(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/final/GetOrderHistoryPage?${queryParams}`, this.options);
  }

  getOrderHistoryFilter(): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/final/GetOrderHistoryFilter`, this.options);
  }

  setOrderFinalPlan(queryParams: any): Observable<any> {
    return this.http.put(`${apiKSFServer}/api/v2/final/SetOrderFinalPlan`, queryParams, this.options);
  }

  setPayOnHalfOfFinal(queryParams: any): Observable<any> {
    return this.http.put(`${apiKSFServer}/api/v2/final/SetPayOnHalfOfFinal`, queryParams, this.options);
  }

  setPayOnRollOfFinal(queryParams: any): Observable<any> {
    return this.http.put(`${apiKSFServer}/api/v2/final/SetPayOnRollOfFinal`, queryParams, this.options);
  }

  delOrderFinalPlan(queryParams: any): Observable<any> {
    return this.http.delete(`${apiKSFServer}/api/v2/final/DelOrderFinalPlan?` + queryParams, this.options);
  }

  // api Upload S3

  uploadFileS3(data: any): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${JSON.parse(localStorage.getItem('access_token-s3'))}`,
        'accept': 'application/json',
      })
    };
    return this.http.post(`${apiUploadS3Url}/upload`, data, options);
  }

  // CustRep
  getCustRepPage(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/cooperator/GetCustRepPage?${queryParams}`
      , this.options);
  }

  getCustRepInfo(queryParams: any): Observable<any> {
    return this.http.post(`${apiKSFServer}/api/v2/cooperator/GetCustRepInfo?${queryParams}`, this.options);
  }

  setCustRepInfo(queryParams: any): Observable<any> {
    return this.http.post(`${apiKSFServer}/api/v2/cooperator/SetCustRepInfo`, queryParams, this.options);
  }

  delCustRepInfo(queryParams: any): Observable<any> {
    return this.http.delete(`${apiKSFServer}/api/v2/cooperator/DelCustRepInfo?${queryParams}`, this.options);
  }

  setCustRepStatus(queryParams: any): Observable<any> {
    return this.http.put(`${apiKSFServer}/api/v2/cooperator/SetCustRepStatus`, queryParams, this.options);
  }

  GetUserProfileByUserId(userId: any): Observable<any> {
    return this.http.post(`${apiKSFServer}/api/v1/UmeUser/GetUserProfileByUserId?userId=${userId}`, {}, this.options);
  }

  getColumnObjectByLink(url): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}` + url, this.options);
  }

  setCustIndiMerge(params): Observable<any> {
    return this.http.put<any>(`${apiKSFServer}/api/v1/UmeUser/SetCustMerge`, params, this.options);
  }

  // đổi tk thụ hưởng

  GetKsbAccountFetch(accountNumber, bankCode) {
    return this.http.get<any>(`${apiKSFServer}/api/v2/account/GetKsbAccountFetch?accountNumber=${accountNumber}&bankCode=${bankCode}`, this.options);
  }

  ChangeBankAccPage(linkApi, queryParams): Observable<any> {
    return this.http.get<any>(`${apiKSFServer}/api/v2/account/${linkApi}?` + queryParams, this.options);
  }

  getChangeBankAccFilter(linkApi): Observable<any> {
    return this.http.get<any>(`${apiKSFServer}/api/v2/account/${linkApi}`, this.options);
  }

  ChangeBankAccGet(queryParams): Observable<any> {
    return this.http.get<any>(`${apiKSFServer}/api/v2/account/ChangeBankAccGet?` + queryParams, this.options);
  }

  changeBankAccOrderPage(queryParams): Observable<any> {
    return this.http.get<any>(`${apiKSFServer}/api/v2/account/ChangeBankAccOrderPage?` + queryParams, this.options);
  }

  changeBankAccHisPage(queryParams): Observable<any> {
    return this.http.get<any>(`${apiKSFServer}/api/v2/account/ChangeBankAccHisPage?` + queryParams, this.options);
  }

  ChangeBankAccDel(queryParams): Observable<any> {
    return this.http.delete<any>(`${apiKSFServer}/api/v2/account/ChangeBankAccDel?` + queryParams, this.options);
  }

  ChangeBankAccAdd(queryParams): Observable<any> {
    return this.http.post<any>(`${apiKSFServer}/api/v2/account/ChangeBankAccAdd`, queryParams, this.options);
  }

  ChangeBankAccUpDate(queryParams): Observable<any> {
    return this.http.put<any>(`${apiKSFServer}/api/v2/account/ChangeBankAccUpDate`, queryParams, this.options);
  }

  changeBankAccSubmit(queryParams): Observable<any> {
    return this.http.put<any>(`${apiKSFServer}/api/v2/account/ChangeBankAccSubmit`, queryParams, this.options);
  }

  changeBankAccApprove(queryParams): Observable<any> {
    return this.http.put<any>(`${apiKSFServer}/api/v2/account/ChangeBankAccApprove`, queryParams, this.options);
  }

  ChangeBankAccApprove(queryParams): Observable<any> {
    return this.http.put<any>(`${apiKSFServer}/api/v2/account/ChangeBankAccApprove?`, queryParams, this.options);
  }

  ChangeBankAccOrderSearch(queryParams): Observable<any> {
    return this.http.get<any>(`${apiKSFServer}/api/v2/account/ChangeBankAccOrderSearch?` + queryParams, this.options);
  }

  ChangeBankAccCheckOrdExists(queryParams): Observable<any> {
    return this.http.get<any>(`${apiKSFServer}/api/v2/account/ChangeBankAccCheckOrdExists?` + queryParams, this.options);
  }

  //end

  // Họp đồng tái tục

  getProductListRoll(queryParams): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/product/GetProductList?` + queryParams, this.options);
  }

  getOrderRollFilter(): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/orderroll/GetOrderRollFilter`, this.options);
  }

  getOrderRollPage(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/orderroll/GetOrderRollPage?` + queryParams, this.options);
  }

  getOrderRollSchemePage(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/orderroll/GetOrderRollSchemePage?${queryParams}`, this.options);
  }

  setOrderRollSchemePage(queryParams: any): Observable<any> {
    return this.http.put(`${apiKSFServer}/api/v2/orderroll/SetOrderRollSchemePage`, queryParams, this.options);
  }

  getOrderRollInfo(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/orderroll/GetOrderRollInfo?${queryParams}`, this.options);
  }

  setOrderRollPlan(queryParams: any): Observable<any> {
    return this.http.put(`${apiKSFServer}/api/v2/orderroll/SetOrderRollPlan`, queryParams, this.options);
  }

  delOrderRollPlan(queryParams: any): Observable<any> {
    return this.http.delete(`${apiKSFServer}/api/v2/orderroll/DelOrderRollPlan?` + queryParams, this.options);
  }

  setPayOnRollOfFinalV2(queryParams: any): Observable<any> {
    return this.http.put(`${apiKSFServer}/api/v2/orderroll/SetPayOnRollOfFinal`, queryParams, this.options);
  }

  setRollNoPayApprovedOfFinal(queryParams: any): Observable<any> {
    return this.http.put(`${apiKSFServer}/api/v2/orderroll/SetRollNoPayApprovedOfFinal`, queryParams, this.options);
  }

  getOpenPolicy(queryParams): Observable<any> {
    return this.http.get<any>(`${apiKSFServer}/api/v2/open/GetOpenPolicy?` + queryParams, this.options);
  }

  setOpenPolicy(params): Observable<any> {
    return this.http.post<any>(`${apiKSFServer}/api/v2/open/SetOpenPolicy`, params, this.options)
  }

  // dữ liệu chi trả
  getOpenConvertPaidPage(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/open/GetOpenConvertPaidPage?` + queryParams, this.options);
  }

  getOpenConvertPaidInfo(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/open/GetOpenConvertPaidInfo?` + queryParams, this.options);
  }

  setConvertPaidInfo(data): Observable<any> {
    return this.http.post(`${apiKSFBaseUrl}/api/v2/open/SetConvertPaidInfo`, data, this.options);
  }

  delConvertPaidInfo(params): Observable<any> {
    return this.http.delete(`${apiKSFBaseUrl}/api/v2/open/DelConvertPaidInfo?` + params, this.options);
  }

  getOpenSchemePageByScheme(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/openscheme/GetOpenSchemePageByScheme?` + queryParams, this.options);
  }


}

